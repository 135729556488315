<template>
  <div class="page">
    <ais-configure :distinct="true" />

    <SearchHero />
    <div class="columns is-multiline is-desktop">
      <FeaturedPrimaryBanner />
      <Search class="column is-5-desktop" @highlight="highlight" @dehighlight="dehighlight" />
      <ais-state-results
        v-if="enableMap"
        id="mapsContainer"
        :class-names="{ 'ais-StateResults': 'column is-7 is-inline-block' }"
      >
        <template slot-scope="{ results: { hits } }">
          <DirectoryMap ref="directorymap" :markers="hits" />
        </template>
      </ais-state-results>
    </div>
  </div>
</template>

<script>
import Search from '@/components/Search.vue';
import SearchHero from '@/components/Global/SearchHero.vue';
import DirectoryMap from '@/components/DirectoryMap.vue';
import FeaturedPrimaryBanner from '@/components/InstantSearch/FeaturedPrimaryBanner.vue';
import { mapState, mapGetters } from 'vuex';
import { icon, point } from 'leaflet';
import iconUrl from 'leaflet/dist/images/marker-icon.png';

export default {
  computed: {
    ...mapState({
      enableMap: (state) => state.map.enabled,
      markers: (state) => state.maps.markers,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
  },
  methods: {
    resetPage() {
      window.scrollTo(0, 0);
      // Remove all highlighted elements
      const allResults = document.querySelectorAll('.ais-hits a.media');
      allResults.forEach((result) => result.classList.remove('has-background-secondary-lighter'));
    },
    highlight(id) {
      if (this.enableMap === false) {
        return;
      }

      const iconToSet = icon({
        iconUrl: '/images/marker-icon-red.png',
        tooltipAnchor: point([17, -20]),
      });

      this.$refs.directorymap.$refs.map.mapObject.eachLayer((layer) => {
        if (layer.options.id === id) {
          layer.setIcon(iconToSet);
          layer.openTooltip(layer.getLatLng());
          layer.setZIndexOffset(1000);
        }
      });
    },
    dehighlight(id) {
      if (this.enableMap === false) {
        return;
      }

      const iconToSet = icon({
        iconUrl,
        tooltipAnchor: point([17, -20]),
      });

      this.$refs.directorymap.$refs.map.mapObject.eachLayer((layer) => {
        if (layer.options.id === id) {
          layer.setIcon(iconToSet);
          layer.closeTooltip();
          layer.setZIndexOffset(900);
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch('advertisements/get');
  },
  components: {
    Search,
    SearchHero,
    DirectoryMap,
    FeaturedPrimaryBanner,
  },
};
</script>
