<template>
  <div id="search-results">
    <ActiveRefinements />
    <ais-configure :hits-per-page.camel="100" />

    <ais-infinite-hits
      :class-names="{
        'ais-InfiniteHits-loadMore': 'loadResults',
        'ais-InfiniteHits-loadPrevious': 'loadResults',
      }"
      :transform-items="transform"
    >
      <template slot="item" slot-scope="{ item, index }">
        <ResultsAd v-if="shouldDisplayAd(index)" :ad-script="getDisplayAd(index)" />
        <router-link
          :id="item.objectID"
          :to="{ name: 'record', params: { record: item.slug }}"
          class="media"
          :class="`${ item.sponsor ? 'is-featured' : '' }`"
          :aria-label="item.name"
          @mouseleave.native.self="$emit('dehighlight', item.objectID)"
          @mouseover.native="$emit('highlight', item.objectID)"
        >
          <FeaturedBadge :sponsored="item.sponsor" />
          <ExpertBadge :expert="item.expert" />
          <RecordImage v-if="item.sponsor" :image-uri="item['image-uri']" :name="item.name" />
          <div class="media-content">
            <h4 class="title is-size-4 is-marginless">
              <span
                v-if="isFilteredView && item.rank !== 9999"
                class="subtitle is-rank"
                v-html="item.rank"
              />
              <ais-highlight :hit="item" attribute="name" />
            </h4>
            <RecordDetailSnapshot v-if="isFilteredView" :record="item" />
            <RecordSponsoredDetails v-if="item.sponsor && !isFilteredView" :record="item" />
          </div>
        </router-link>
      </template>
    </ais-infinite-hits>
    <NoResults />
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from 'vuex';
import { createInfiniteHitsSessionStorageCache } from 'instantsearch.js/es/lib/infiniteHitsCache';

export default {
  data() {
    return {
      cache: createInfiniteHitsSessionStorageCache(),
    };
  },
  computed: {
    ...mapState('advertisements', {
      results_ads: (state) => state.results_ads,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    isFilteredView() {
      return Object.keys(this.$route.query).includes(this.currentDirectory.primary_filter.field);
    },
  },
  methods: {
    shouldDisplayAd(index) {
      // if the index is a value of 4 and there are still ads remaining
      if (!this.results_ads || !this.results_ads.length) {
        return false;
      }

      return (
        index > 0 && index % 4 === 0 && index / 4 <= this.results_ads.length
      );
    },
    getDisplayAd(index) {
      return this.results_ads[index / 4 - 1];
    },
    getRank(record, currentFilter) {
      // Gets only the FIRST key element for the field.
      // For example, `lists.key` is the primary filter, but we only want to get `lists`
      // as the field.
      const recordField = record[this.currentDirectory.primary_filter.field.split('.')[0]];

      // Change `list` to `key` when records in Algolia are updated
      const validRecord = recordField?.find((field) => field.key === currentFilter);

      // Return a "rank" of super high so it shows at the bottom of the list temporarily
      // before it's hidden by the facet filter again.
      return validRecord?.rank ?? 9999;
    },
    transform(items) {
      const filter = this.$router.currentRoute.query?.[this.currentDirectory.primary_filter.field];

      if (filter === undefined) {
        return items;
      }

      return items
        .sort((one, two) => (this.getRank(one, filter) - this.getRank(two, filter)))
        .map((item) => ({ ...item, rank: this.getRank(item, filter) }));
    },
  },
  components: {
    ActiveRefinements: () => import('@/components/InstantSearch/ActiveRefinements.vue'),
    ResultsAd: () => import('@/components/Ads/ResultsAd.vue'),
    FeaturedBadge: () => import('@/components/Badges/Featured.vue'),
    ExpertBadge: () => import('@/components/Badges/Expert.vue'),
    RecordImage: () => import('@/components/SearchResults/RecordImage.vue'),
    RecordRank: () => import('@/components/InstantSearch/RecordRank.vue'),
    RecordDetailSnapshot: () => import('@/components/InstantSearch/RecordDetailSnapshot.vue'),
    RecordSponsoredDetails: () => import('@/components/SearchResults/RecordSponsoredDetails.vue'),
    NoResults: () => import('@/components/InstantSearch/NoResults.vue'),
  },
};
</script>
