<template>
  <ais-state-results class="column is-12 is-paddingless">
    <div
      v-if="isFeaturedPrimary(facetsRefinements)"
      slot-scope="{ state: { facetsRefinements } }"
      class="is-featured-primary-banner"
    >
      <h1 class="title is-1">
        {{ primaryFilterData(facetsRefinements).name }}
      </h1>
      <p class="subtitle" v-html="primaryFilterData(facetsRefinements).description" />
    </div>
    <span v-else />
  </ais-state-results>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    primaryFilter() {
      return this.currentDirectory.primary_filter;
    },
  },
  methods: {
    isFeaturedPrimary(activeFacets) {
      const primaryFields = this.currentDirectory.primary_filter
        .featured.map((field) => field.name);

      return activeFacets[this.primaryFilter.field].some((facet) => primaryFields.includes(facet));
    },
    primaryFilterData(activeFacets) {
      const activeFacet = activeFacets?.[this.primaryFilter.field]?.[0];
      return this.primaryFilter.featured
        .find((field) => field.name === activeFacet);
    },
  },
};
</script>
